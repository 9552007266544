import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Layout from "@/layout";

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    name: "Login",
    meta: { title: '登录' },
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    meta: { title: '404' },
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    meta: { title: '401' },
    hidden: true,
  },
  {
    path: "/dashboard",
    component: Layout,
    children: [
      {
        path: "/dashboard",
        component: () => import("@/views/dashboard/index"),
        name: "Dashboard",
        meta: { title: "首页", icon: "el-icon-s-home" },
      },
    ],
  },
  // {
  //   path: "/medical-education",
  //   component: Layout,
  //   meta: { title: "医学教育", icon: "el-icon-s-home" },
  //   children: [
  //     {
  //       path: "/medical-education/course-manage",
  //       component: () => import("@/views/medical-education/course-manage/index"),
  //       name: "courseManage",
  //       meta: { title: "课程管理" },
  //     },
  //     {
  //       path: "/medical-education/course-manage/serise-course/add",
  //       component: () => import("@/views/medical-education/course-manage/add-serise-course/index"),
  //       name: "addSeriseCourseManage",
  //       meta: { title: "添加系列课程" },
  //     },
  //     {
  //       path: "/medical-education/course-manage/single-course/add",
  //       component: () => import("@/views/medical-education/course-manage/add-single-course/index"),
  //       name: "addSingleCourseManage",
  //       meta: { title: "添加单节课程" },
  //     },
  //     {
  //       path: "/medical-education/course-manage/sub-course",
  //       component: () => import("@/views/medical-education/course-manage/sub-course/index"),
  //       name: "subCourseManage",
  //       meta: { title: "子节课程管理" },
  //     },
  //     {
  //       path: "/medical-education/course-manage/sub-course/add",
  //       component: () => import("@/views/medical-education/course-manage/sub-course/add/index"),
  //       name: "addSubCourseManage",
  //       meta: { title: "添加子节课程" },
  //     },
  //     {
  //       path: "/medical-education/credit-manage",
  //       component: () => import("@/views/medical-education/credit-manage/index"),
  //       name: "creditManage",
  //       meta: { title: "学分管理" },
  //     },
  //     {
  //       path: "/medical-education/instructor-manage",
  //       component: () => import("@/views/medical-education/instructor-manage/index"),
  //       name: "instructorManage",
  //       meta: { title: "授课人管理" },
  //     },
  //     {
  //       path: "/medical-education/instructor-manage/add",
  //       component: () => import("@/views/medical-education/instructor-manage/add/index"),
  //       name: "addInstructorManage",
  //       meta: { title: "添加授课人" },
  //     },
  //     {
  //       path: "/medical-education/course-order-manage",
  //       component: () => import("@/views/medical-education/course-order-manage/index"),
  //       name: "courseOrderManage",
  //       meta: { title: "课程订单管理" },
  //     },
  //   ],
  // },
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [];

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter () {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
