import store from '@/store'

function checkPermission(el, binding) {
  const { value } = binding
  let permissions = store.state && store.state.permission.permissions
  // console.log('permissions: ', permissions)

  if (!(permissions && permissions.length)) {
    el.parentNode && el.parentNode.removeChild(el)
    return
  }
 
  if (!permissions.includes(value)) {
    el.parentNode && el.parentNode.removeChild(el)
  }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  // update(el, binding) {
  //   checkPermission(el, binding)
  // }
}
