import request from '@/utils/request'

export function getRoleList(data) {
  return request({
    url: '/internet/hospital/role/index',
    method: 'post',
    data
  })
}

export function addRole(data) {
  return request({
    url: '/internet/hospital/role/create',
    method: 'post',
    data
  })
}

export function editRole(data) {
  return request({
    url: '/internet/hospital/role/update',
    method: 'post',
    data
  })
}

export function detailRole(id) {
  return request({
    url: '/internet/hospital/role/detail',
    method: 'post',
    data: { id }
  })
}

export function deleteRole(id) {
  return request({
    url: '/internet/hospital/role/delete',
    method: 'post',
    data: { id }
  })
}

export function getAdminRoles(data) {
  return request({
    url: '/internet/hospital/admin/roles',
    method: 'post',
    data
  })
}

export function addRolePermission(data) {
  return request({
    url: '/internet/hospital/role/permissions',
    method: 'post',
    data
  })
}

export function getPermissions(data) {
  return request({
    url: '/internet/hospital/menus',
    method: 'post',
    data
  })
}

export function getMenus(id) {
  return request({
    url: '/internet/hospital/permission/index',
    method: 'post',
    data: { role_id: id }
  })
}

//查询操作记录
export function getLogout (data) {
  return request({
    url: '/user/operation_logs',
    method: 'get',
    data,
  })
}
//操作详情
export function getLogoutDetail (id) {
  return request({
    url: `/user/operation_logs_info/${id}`,
    method: 'get',
  })
}