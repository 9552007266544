/*
 * @Author: your name
 * @Date: 2021-03-11 10:03:58
 * @LastEditTime: 2021-03-23 17:44:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \internet-hospital-admin\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import permission from '@/directive/permission'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import animated from 'animate.css'

import '@/styles/index.scss' // global css
import '@/utils/permission' // permission control
import './icons' // icon
import onlyNumber from '@/directive/el-input';
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(onlyNumber);

Vue.use(permission)
Vue.use(ElementUI)
Vue.use(animated)

Vue.config.productionTip = false

const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue