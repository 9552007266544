import router, { resetRouter } from '../router'
import store from '../store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import Vue from '@/main'
import { updatePassword } from '@/api/power/user'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = to.meta.title

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next('/')
      NProgress.done()
    } else {
      try {
        let routes = store.state.permission.routes
        if (!routes || !routes.length) {
          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes')

          // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } else {
          let update_pass = JSON.parse(localStorage.getItem('update_pass'))
          if (update_pass) {
            const _this = Vue
            let h = _this.$createElement;

            let NewPassword = ''
            let surePassword = ''

            _this.$msgbox({
              title: '提示',
              message: h('div', null, [
                h('div', {
                  style: 'margin-bottom: 20px'
                }, '该登陆密码已过3个月未更新，为了保证您的信息安全，请修改登陆密码！'),
                h('div', {
                  style: 'margin-bottom: 10px'
                }, [
                  h('label', {
                    style: "width:56px;display: inline-block;"
                  }, '新密码'),
                  h('input', {
                    style: "outline-style: none;border: 1px solid #ccc; border-radius: 3px;padding: 10px 10px;font-size: 14px;margin-left:10px",
                    attrs: {
                      placeholder: "请输入新密码",
                    },
                    on: {
                      input: function (event) {
                        _this.newPassword = event.target.value;
                      },
                    },
                  }, '')
                ]),
                h('div', null, [
                  h('label', { style: "width:56px;display: inline-block;" }, '确认密码'),
                  h('input', {
                    style: "outline-style: none;border: 1px solid #ccc; border-radius: 3px;padding: 10px 10px;font-size: 14px;margin-left:10px",
                    attrs: {
                      placeholder: "请输入确认密码"
                    },
                    on: {
                      input: function (event) {
                        _this.surePassword = event.target.value;
                      },
                    },
                  }, '')
                ])
              ]),
              showCancelButton: false,
              showClose: false,
              closeOnClickModal: false,
              confirmButtonText: '提交',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  instance.confirmButtonLoading = true;
                  instance.confirmButtonText = '验证中...';

                  if (!_this.newPassword) {
                    Message.error('请输入新密码')
                    setTimeout(() => {
                      instance.confirmButtonText = '提交';
                      instance.confirmButtonLoading = false;
                    }, 300);
                    return
                  }

                  if (!_this.surePassword) {
                    Message.error('请输入确认新密码')
                    setTimeout(() => {
                      instance.confirmButtonText = '提交';
                      instance.confirmButtonLoading = false;
                    }, 300);
                    return
                  }

                  if (_this.newPassword !== _this.surePassword) {
                    Message.error('两次密码不一致，请重新输入')
                    setTimeout(() => {
                      instance.confirmButtonText = '提交';
                      instance.confirmButtonLoading = false;
                    }, 300);
                    return
                  }

                  let reg = /^(?=.*\d)(?=.*[A-Za-z])(?=.*[!@$%^*?~])[A-Za-z0-9!@$%^*?~]{8,16}$/
                  if (!reg.test(_this.newPassword)) {
                    Message.error('请输入数字+字母大写+字母小写+特殊字符组合而成的8-16位密码')
                    setTimeout(() => {
                      instance.confirmButtonText = '提交';
                      instance.confirmButtonLoading = false;
                    }, 300);
                    return
                  }

                  if (!reg.test(_this.surePassword)) {
                    Message.error('请输入数字+字母大写+字母小写+特殊字符组合而成的8-16位密码')
                    setTimeout(() => {
                      instance.confirmButtonText = '提交';
                      instance.confirmButtonLoading = false;
                    }, 300);
                    return
                  }

                  setTimeout(() => {
                    done();
                    setTimeout(() => {
                      instance.confirmButtonLoading = false;
                    }, 300);
                  }, 2000);
                } else {
                  done();
                }
              }
            }).then(action => {
              updatePassword({ new_password: _this.newPassword, new_password_confirmation: _this.surePassword }).then(async res => {
                await store.dispatch('user/resetToken').then(response => {
                  localStorage.clear()
                  location.reload()
                })
              })
            });
          }
          next()

          if (to.matched.length === 0) {
            next('/')
            NProgress.done()
          }
        }
        
      } catch (error) {
        // remove token and go to login page to re-login
        await store.dispatch('user/resetToken')
        Message.error(error || 'Has Error')
        next(`/login?redirect=${to.fullPath}`)
        NProgress.done()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.fullPath}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
