/*
 * @Author: your name
 * @Date: 2021-03-11 10:03:58
 * @LastEditTime: 2021-11-12 10:16:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \internet-hospital-admin\src\utils\auth.js
 */
const TokenKey = 'token'
const MY_USER_NAME = 'my_user_name'

const MY_USER_ID = 'my_user_id'

export function getToken () {
  return localStorage.getItem(TokenKey)
}

export function setToken (token) {
  return localStorage.setItem(TokenKey, token)
}


export function removeToken () {
  return localStorage.removeItem(TokenKey)
}


export function getName () {
  return localStorage.getItem(MY_USER_NAME)
}

export function setName (name) {
  return localStorage.setItem(MY_USER_NAME, name)
}

export function removeName () {
  return localStorage.removeItem(MY_USER_NAME)
}

export function getUserId () {
  return localStorage.getItem(MY_USER_ID)
}

export function setUserId (name) {
  return localStorage.setItem(MY_USER_ID, name)
}

export function removeUserId () {
  return localStorage.removeItem(MY_USER_ID)
}

