

import request from '@/utils/request'


/**
 * @description: 文件上传
 * @param {*}
 * @return {*}
 */

export function upload (params) {
  return request({
    url: '/internet/hospital/upload',
    method: 'post',
    data: params
  })
}



export function pharmacyList (params) {
  return request({
    url: '/pharmacy/search',
    method: 'post',
    data: params
  })
}


/**
 * @description: 剂型筛选列表
 * @param {*}
 * @return {*}
 */

export function dosageList (params) {
  return request({
    url: '/pharmacy/dosage',
    method: 'post',
    data: params
  })
}


/**
 * @description: 诊断列表
 * @param {*}
 * @return {*}
 */

export function icdList (params) {
  return request({
    url: '/internet/hospital/icd/list',
    method: 'post',
    data: params
  })
}

/**
 * @description: 查询药房下的中药材列表
 * @param {*}
 * @return {*}
 */

export function chinesedrugList (params) {
  return request({
    url: '/prescript/chinesedrug',
    method: 'post',
    data: params
  })
}

/**
 * @description: 查询药房下的西药材列表
 * @param {*}
 * @return {*}
 */


export function westerndrugList (params) {
  return request({
    url: '/prescript/westerndrug',
    method: 'post',
    data: params
  })
}



/**
 * @description: 初复诊字典
 * @param {*}
 * @return {*}
 */


export function visitOptions (params) {
  return request({
    url: '/module/controller/action',
    method: 'post',
    data: params
  })
}

/**
 * @description: 查询平台所有医生功能
 * @param {*}
 * @return {*}
 */

export function doctorsSearch (params) {
  return request({
    url: '/prescript/doctors_search',
    method: 'post',
    data: params
  })
}


/**
 * @description: 查询关联医生功能
 * @param {*}
 * @return {*}
 */

export function associatedDoctors (params) {
  return request({
    url: '/prescript/doctors',
    method: 'post',
    data: params
  })
}

/**
 * 医院搜索
 * @param {*} data 
 * @returns 
 */
export function searchHospital (data) {
  return request({
    url: '/internet/hospital/hospital/search',
    method: 'post',
    data
  })
}

/**
 * 患者搜索
 * @param {*} data
 * @returns
 */

export function searchPatient (data) {
  return request({
    url: '/internet/hospital/patient/search',
    method: 'post',
    data
  })
}


/**
 * 获取医生分成比例
 * @param {*} data
 * @returns
 */


export function getDoctorScale (data) {
  return request({
    url: '/drugorder/getDoctorScale',
    method: 'post',
    data
  })
}

/**
 * 获取oss cf
 * @param {*}
 * @returns 
 */
export function getOssUpload (data) {
  return request({
    url: '/union/getOssUpload',
    method: 'get',
    data
  })
}


//问诊订单导出
export function exportList (params) {
  return request({
    url: '/visit/export',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

// 获取手机验证码
export function getCodeApi (mobile) {
  return request({
    url: `/doctor/ca/smsCode`,
    method: 'get',
    data: {mobile}
  })
}

