/*
 * @Author: your name
 * @Date: 2021-03-17 09:35:19
 * @LastEditTime: 2021-04-28 14:54:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \internet-hospital-admin\src\store\modules\main.js
 */
import { pharmacyList, dosageList, icdList } from '@/api/common'
import { getToken, setToken } from '@/utils/auth'

const state = {
  options: sessionStorage.getItem('options') || {}
}

const mutations = {
  SET_OPTIONS: (state, data) => {
    state.options = Object.assign(state.options, data)
    sessionStorage.setItem('options', state.options)
  },
}

const actions = {
  // user login
  async setOptions ({ commit }) {

    await pharmacyList().then((res) => {
      let data = {
        pharmacyList: res.data.list
      }
      commit('SET_OPTIONS', data)
    })
    await dosageList().then((res) => {
      let data = {
        dosageList: res.data.list
      }
      commit('SET_OPTIONS', data)
    })

    // console.log(_pharmacyList, _dosageList)
  },

}

const getters = {
  getOptions: (state) => {
    return state.options
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
