import axios from "axios";
import Qs from "qs";
import { MessageBox, Message, Loading } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";

let loadingInstance = null;

// create an axios instance
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000, // request timeout
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
});

// request interceptor
request.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (config.data && config.data.loading != 'false') {
      loadingInstance = Loading.service({
        text: "拼命加载中...",
      });
    }
    let token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (config.method.toLowerCase() === "get") {
      config.params = config.data;
    }

    if (config.method.toLowerCase() === "post") {
      config.data = Qs.stringify(config.data);
    }

    if (config.method.toLowerCase() === "put") {
      config.data = Qs.stringify(config.data);
    }

    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
request.interceptors.response.use(
  (response) => {
    if (loadingInstance) {
      loadingInstance.close();
    }

    const res = response.data;

    if (response.config.responseType === 'blob') {
      return res
    }

    // if the custom code is not 200, it is judged as an error.
    if (res.code == 200 || res.status === 200) {
      return res;
    }else if (res.code == 400 && res.message == '请先登录') {
      // MessageBox.alert(
      //   "您已经登出,请重新登录",
      //   "确认注销",
      //   {
      //     confirmButtonText: "重新登录",
      //     type: "warning",
      //     callback: action => {
      store.dispatch("user/resetToken").then(() => {
        location.reload();
      });
      //   }
      // }
      // );
    } else {
      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(res.message || "Error"));
    }
  },
  (error) => {
    console.log("error" + error); // for debug

    if (loadingInstance) {
      loadingInstance.close();
    }

    if (error.response && error.response.status === 403) {
      // to re-login
      MessageBox.confirm(
        "您已经登出，您可以取消留在此页面，或重新登录",
        "确认注销",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        store.dispatch("user/resetToken").then(() => {
          location.reload();
        });
      });
    } else if (error.response && error.response.status === 401) {
      Message({
        message: '没有权限, 您可以找管理员分配权限!',
        type: "error",
        duration: 5 * 1000,
      });
    } else {
      Message({
        message: error.message,
        type: "error",
        duration: 5 * 1000,
      });
    }

    return Promise.reject(error);
  }
);

export default request;
