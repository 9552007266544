/*
 * @Author: your name
 * @Date: 2021-03-11 10:03:58
 * @LastEditTime: 2021-03-18 13:56:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \internet-hospital-admin\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import permission from './modules/permission'
import main from './modules/main'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    main,
  },
  // plugins: [createPersistedState({ storage: window.sessionStorage })]
})
