import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/internet/hospital/login',
    method: 'post',
    data
  })
}


export function loginCode(datas) {
  return request({
    url: '/login/sendsmsCode',
    method: 'get',
    data:{mobile: datas}
  })
}


export function getInfo(token) {
  return request({
    url: '/internet/hospital/me',
    method: 'post',
    data: { token }
  })
}

export function logout(token) {
  return request({
    url: '/internet/hospital/logout',
    method: 'post',
    data: { token }
  })
}

export function getUserList(data) {
  return request({
    url: '/internet/hospital/admin/index',
    method: 'post',
    data
  })
}

export function addUser(data) {
  return request({
    url: '/internet/hospital/admin/create',
    method: 'post',
    data
  })
}

export function editUser(data) {
  return request({
    url: '/internet/hospital/admin/update',
    method: 'post',
    data
  })
}

export function deleteUser(id) {
  return request({
    url: '/internet/hospital/admin/delete',
    method: 'post',
    data:{ id }
  })
}

export function detailUser(id) {
  return request({
    url: '/internet/hospital/admin/detail',
    method: 'post',
    data:{ id }
  })
}

export function searchPharmacy() {
  return request({
    url: '/internet/hospital/admin/pharmacies',
    method: 'post',
    data: {}
  })
}

//修改密码
export function updatePassword (params) {
  return request({
    url: 'internet/hospital/update_pass',
    method: 'post',
    data: params
  })
}