import { constantRoutes, asyncRoutes } from '@/router'
import { getPermissions } from '@/api/power/role'

// const asyncRoutes = [
//   {
//     id: 1,
//     name: '权限管理',  
//     path: '/power',
//     component: 'layout',
//     icon: 'el-icon-setting',
//     hidden: false,
//     sort: 1,
//     children: [
//       {
//         id: 11,
//         parentId: 1, // 上级菜单
//         name: '菜单管理', // 菜单名称
//         path: 'menu?id=111&title=xxx', // 菜单路径
//         component: 'power/menu', // 前端组件
//         icon: 'el-icon-menu', // 图标
//         hidden: false, // 隐藏路由
//         sort: 1, // 排序
//       },
//       {
//         id: 12,
//         parentId: 1,
//         name: '角色管理',
//         path: 'role',
//         component: 'power/role',
//         icon: 'el-icon-s-check',
//         hidden: false,
//         sort: 2,
//       }
//     ]
//   }, 
// ]

/**
 * 生成动态路由
 * @param {*} routes 
 * @returns 
 */
export function generateAsyncRoutes(routes) {
  let asyncRoutes = []
  for (let item of routes) {
    let component = ''
    if (item.component && item.component.includes('layout')) {
      component = `${item.component}`
    } else {
      component = `views/${item.component}`
    }

    let loadComponent = (resolve) => require([`@/${component}/index.vue`], resolve)

    let path = ''
    let query = {}
    if (item.path && item.path.indexOf('?') > -1) {
      path = item.path.split('?')[0]
      let queryArray = item.path.split('?')[1].split('&')
      queryArray.map((item) => {
        let temp = item.split('=')
        if (temp.length) {
          query[temp[0]] = temp[1]
        }
      })
    } else {
      path = item.path
    }

    let route = {
      path,
      query,
      redirect: item.redirect,
      component: loadComponent,
      hidden: item.hidden,
      meta: {
        title: item.name,
        icon: item.icon
      }
    }
  
    if (item.children && item.children.length) {
      route.children = generateAsyncRoutes(item.children)
    }

    asyncRoutes.push(route)
  }
  return asyncRoutes
}

const state = {
  routes: [],
  addRoutes: [],
  permissions: [],
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  }
}

const actions = {
  generateRoutes({ commit }) {
    return new Promise(resolve => {
      getPermissions().then(res => {
        // const accessedRoutes = asyncRoutes
        const asyncRoutes = res.data.rows || []
        const accessedRoutes = generateAsyncRoutes(asyncRoutes)
        
        const permissions = res.data.permissions || []
        commit('SET_ROUTES', accessedRoutes)
        commit('SET_PERMISSIONS', permissions)
        resolve(accessedRoutes)
      }).catch(() => {
        commit('SET_ROUTES', [])
        commit('SET_PERMISSIONS', [])
        resolve([])
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
