import { login, logout, getInfo } from '@/api/power/user'
import { getToken, setToken, removeToken, getName, setName, removeName, getUserId, setUserId, removeUserId } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import store from '@/store'

const state = {
  token: getToken(),
  account: '',
  name: getName(),
  userId: getUserId(),
  avatar: '',
  roles: [],
  update_pass: false, //为true时 三个月内未更新密码 去更新密码
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
    setName(name)
  },
  SET_USER_ID: (state, id) => {
    state.userId = id
    setUserId(id)
  },
  SET_ACCOUNT: (state, account) => {
    state.account = account
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_UPDATE_PASS: (state, update_pass) => {
    state.update_pass = update_pass
  }
}

const actions = {
  // user login
  login ({ commit }, userInfo) {
    const { username, password,code } = userInfo
    return new Promise((resolve, reject) => {
      login({ account: username.trim(), password: password,code:code}).then(async res => {
        const { data } = res
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        localStorage.setItem('account', data.account)
        commit('SET_NAME', data.name)
        commit('SET_USER_ID', data.id)
        localStorage.setItem('update_pass', data.update_pass)
        commit('SET_UPDATE_PASS', data.update_pass)
        // 重置路由  
        const accessRoutes = await store.dispatch('permission/generateRoutes')
        router.addRoutes(accessRoutes)
        resolve()
      }).catch(error => {
        reject(error)
      })

      // setToken('jsddflsjdlsj')

      // store.dispatch('permission/generateRoutes').then(accessRoutes => {
      //   router.addRoutes(accessRoutes)
      //   resolve()
      // })

    })
  },

  // get user info
  getInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(res => {
        const { data } = res

        if (!data) {
          reject('验证失败，请重新登录')
        }

        const { roles, name, avatar } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        localStorage.clear()
        resetRouter()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
